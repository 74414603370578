import React, { useState } from 'react';
import './layout-header-mobile.scss';
import { CloseIcon } from '../../../Icons/close-icon';
import { LogoBrandSmall } from '../logo-brand-small';
import { useTranslation } from 'react-i18next';
import { ChevronBottomIcon } from '../../../Icons/chevron-bottom-icon';
import { ChevronTopIcon } from '../../../Icons/chevron-top-icon';
import { LockIcon } from '../../../Icons/lock-icon';
import { I_NAV_OPTIONS, NAV_OPTIONS } from './config';
import PartnerIcon from '../../../Icons/partner-icon';
import { theme } from '../../../Style/theme';
import { useStores } from '../../../Hooks/use-stores';
import ButtonPartnerAccessDashboard from './Components/button-partner-access-dashboard';
import HeaderSelectLanguage from './Components/select-language';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';
import CONSTANTS from '../../../Constants';
import { getKeyByValueRecursive } from '../../../Utils/object';
import Copydeck from '../../../i18n/Copydeck';

interface LayoutHeaderMobileProps {
    onClose: () => void;
}

const LayoutHeaderMobile: React.FC<LayoutHeaderMobileProps> = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState<any>({
        experience: false,
        aboutUs: false,
        supportRessources: false,
    });
    const { t } = useTranslation();
    const nav = useCustomNavigation();
    const [alreadySelected, setAlreadySelected] = useState<any>(false);

    const { userStore, languageStore } = useStores();

    const handleOnClose = () => onClose();

    const myChildrenHasSelected = (navOption: I_NAV_OPTIONS, currentPath: string | undefined) => {
        if (!navOption.content) return false;
        let result = false;

        navOption.content.forEach((content) => {
            if (content.href === currentPath) result = true;
        });

        if (result && !alreadySelected) {
            setAlreadySelected(true);
            setIsOpen((prev: any) => ({
                ...prev,
                [navOption.title]: prev?.[navOption.title] ? false : true,
            }));
        }
        return result;
    };

    return (
        <div className="LayoutHeaderMobile">
            <div className="menu-burger-close" onClick={handleOnClose}>
                <CloseIcon />
            </div>

            <div className="mobile-nav-wrapper">
                <div className="mobile-nav-content">
                    <div className="content-actions">
                        <a className="container-LogoBrandSmall" href="/">
                            <LogoBrandSmall />
                        </a>

                        {NAV_OPTIONS.map((navOption) => {
                            const pathOfRoutes = (CONSTANTS.ROUTES as any)?.[
                                languageStore.currentLanguage as any
                            ]?.[navOption?.href];
                            const path = window.location.pathname;
                            const keyRoutesOfPath = getKeyByValueRecursive(CONSTANTS.ROUTES, path);
                            let currentPath = '';

                            if (keyRoutesOfPath) {
                                const lastKey: any = keyRoutesOfPath.split('.').pop();
                                if (lastKey)
                                    currentPath = (CONSTANTS.ROUTES as any)?.[
                                        (languageStore as any).currentLanguage
                                    ]?.[lastKey];
                            }

                            const isNotClickable = navOption?.href === '#';
                            const isCurrentSelected =
                                currentPath === pathOfRoutes ||
                                myChildrenHasSelected(navOption, keyRoutesOfPath?.split('.').pop());

                            return (
                                <div>
                                    <div
                                        className={`navigation-one-line ${
                                            isNotClickable ? '' : 'cursor-pointer'
                                        }`}
                                    >
                                        {isNotClickable ? (
                                            <p
                                                className={`navigation-title text-primary-font ${
                                                    isCurrentSelected ? 'nav-item-selected' : ''
                                                } cursor-pointer`}
                                                onClick={() => {
                                                    setIsOpen((prev: any) => ({
                                                        ...prev,
                                                        [navOption.title]: prev?.[navOption.title]
                                                            ? false
                                                            : true,
                                                    }));
                                                }}
                                            >
                                                {t(navOption.title)}
                                            </p>
                                        ) : navOption.type === 'external' ? (
                                            <a
                                                href={navOption.href}
                                                className={`navigation-title text-primary-font ${
                                                    isCurrentSelected ? 'nav-item-selected' : ''
                                                }`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t(navOption.title)}
                                            </a>
                                        ) : (
                                            <div
                                                onClick={() => nav(navOption.href)}
                                                className={`navigation-title text-primary-font ${
                                                    isCurrentSelected ? 'nav-item-selected' : ''
                                                }`}
                                                rel="noopener noreferrer"
                                            >
                                                {t(navOption.title)}
                                            </div>
                                        )}

                                        {navOption.content && (
                                            <div
                                                onClick={() => {
                                                    setIsOpen((prev: any) => ({
                                                        ...prev,
                                                        [navOption.title]: prev?.[navOption.title]
                                                            ? false
                                                            : true,
                                                    }));
                                                }}
                                            >
                                                {isOpen?.[navOption?.title || ''] ? (
                                                    <ChevronTopIcon
                                                        fill={
                                                            isCurrentSelected
                                                                ? theme[
                                                                      'surface-brand-rest-primary'
                                                                  ]
                                                                : theme['black']
                                                        }
                                                    />
                                                ) : (
                                                    <ChevronBottomIcon
                                                        fill={
                                                            isCurrentSelected
                                                                ? theme[
                                                                      'surface-brand-rest-primary'
                                                                  ]
                                                                : theme['black']
                                                        }
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    {navOption.content && isOpen?.[navOption?.title || '']
                                        ? navOption.content.map((content) => {
                                              const pathOfRoutes = (CONSTANTS.ROUTES as any)?.[
                                                  languageStore.currentLanguage as any
                                              ]?.[content.href];
                                              const isCurrentSelected =
                                                  currentPath === pathOfRoutes;

                                              return (
                                                  <div
                                                      onClick={() => nav(content.href)}
                                                      className={`text-primary-font sub-navigation-title subitem-${
                                                          content.type
                                                      } deprecated-text-title-medium ${
                                                          isCurrentSelected
                                                              ? 'nav-item-selected'
                                                              : ''
                                                      }`}
                                                  >
                                                      {t(content.title)}

                                                      {content.type === 'disabled'
                                                          ? `${'(' + t('coming_soon') + ')'}`
                                                          : null}
                                                      {content.type === 'locked' ? (
                                                          <div className="nav-subitem-locked">
                                                              <LockIcon />
                                                          </div>
                                                      ) : null}
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                            );
                        })}

                        <div className="separator" />

                        <ButtonPartnerAccessDashboard
                            label={
                                userStore.isLogin()
                                    ? t('HeaderNav.partner_access')
                                    : t('HeaderNav.partner_access_no_login')
                            }
                            icon={<PartnerIcon fill={theme['surface-brand-rest-tertiary']} />}
                            onClose={() => {
                                handleOnClose();
                            }}
                        />

                        {userStore.isLogin() ? null : (
                            <div
                                onClick={() => {
                                    nav(CONSTANTS.SCREEN.LOGIN);
                                }}
                                className="btn-delete-default-style text-body-medium-primary-font-bold cta-login text-element-brand-rest-primary"
                            >
                                {Copydeck.loginLoginSpace}
                            </div>
                        )}

                        <div className="container-header-select-language">
                            <HeaderSelectLanguage />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayoutHeaderMobile;
