import React, { useRef, useEffect, useState } from 'react';
import './index.scss';
import useHandleAnimation from '../../../../Hooks/use-handle-animation';
import { LogoBrand } from '../../../../Layout/Components/logo-brand';
import { theme } from '../../../../Style/theme';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import { useTranslation } from 'react-i18next';

const VogueSection = () => {
    const overlayRef = useRef(null);
    const videoRef = useRef(null);
    const [overlaySize, setOverlaySize] = useState({ width: 0, height: 0 });
    const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
    const { windowHeight, windowWidth } = useWindowDimensions();
    const { t } = useTranslation();
    const isMobile = windowWidth < 768;

    useHandleAnimation('.VogueSection', '-visible');
    useHandleAnimation('.overlay-text', '-visible');
    useHandleAnimation('.overlay-text-reverse', '-visible');
    useHandleAnimation('.video', '-visible');

    useHandleAnimation('.text-overlay-fix', '-visible');
    useHandleAnimation('.text-overlay-fix-bottom', '-visible');

    useEffect(() => {
        const updateSize = () => {
            if (overlayRef.current) {
                const { width, height } = (overlayRef.current as any).getBoundingClientRect();
                setOverlaySize({ width, height: height + 100 });
            }

            if (videoRef.current) {
                const { width, height } = (videoRef.current as any).getBoundingClientRect();
                setVideoSize({ width, height });
            }
        };

        setTimeout(() => {
            updateSize();
        }, 1000);

        window.addEventListener('resize', updateSize);

        return () => window.removeEventListener('resize', updateSize);
    }, [windowHeight, windowWidth]);

    return (
        <div
            className="VogueSection"
            id="VogueSection"
            style={{
                height: isMobile ? videoSize.height : overlaySize.height,
            }}
        >
            <div className="container">
                {isMobile ? (
                    <video
                        ref={videoRef}
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="video -is-mobile"
                        key={videoSize.width}
                    >
                        <source
                            src={require('../../../../Assets/MP4/vogue-business-mobile.mp4')}
                            type="video/mp4"
                        />
                    </video>
                ) : (
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="video"
                        key={overlaySize.width}
                    >
                        <source
                            src={require('../../../../Assets/MP4/vogue.mp4')}
                            type="video/mp4"
                        />
                    </video>
                )}

                <div className="text-overlay-fix -top text-white">
                    <div className="logo-container cursor-pointer">
                        <LogoBrand fill={theme['white']} width={100} />
                    </div>
                    <span className="text-white">{t('VogueSection.VogueSectionPartnering')}</span>
                </div>

                <img
                    ref={overlayRef}
                    src={
                        isMobile
                            ? require('./../../../../Assets/Img/Home/vogue-static-mobile.png')
                            : require('./../../../../Assets/Img/Home/vogue-static.png')
                    }
                    alt="Vogue"
                    className="overlay-text"
                />

                <img
                    src={
                        isMobile
                            ? require('./../../../../Assets/Img/Home/vogue-static-white-mobile.png')
                            : require('./../../../../Assets/Img/Home/vogue-static-white.png')
                    }
                    alt="Vogue"
                    className="overlay-text-reverse"
                />

                <div className="text-overlay-fix -bottom text-white t-align-center text-overlay-fix-bottom">
                    <span>{t('VogueSection.VogueSectionDescription')}</span>

                    <span> </span>
                    <span> </span>

                    <span
                        className="description-2"
                        dangerouslySetInnerHTML={{
                            __html: t('VogueSection.VogueSectionDescription2'),
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default VogueSection;
