import Service from '..';

import i18n from 'i18next';
import CONSTANTS from '../../Constants';

export interface AnnoucementDto {
    PictureOrVideo: {
        data: {
            attributes: {
                url: string;
                ext: string;
                mime: string;
            };
        };
    };
    PictureOrVideoMobile: {
        data?: {
            attributes: {
                url: string;
                ext: string;
                mime: string;
            };
        };
    };
    Title: string;
    TitleES: string;
    TitleFR: string;
    TitleIT: string;
    redirectionUrl: string;
    id?: string;
}

export interface PaginationDto {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
}

interface AnnoucementServiceInterface {}

class AnnoucementService extends Service implements AnnoucementServiceInterface {
    private buildUrl(url: string): string {
        return `https://superb-actor-37d8b69f79.strapiapp.com/api/${url}`;
    }

    public async closeAnnoucement(id: string): Promise<boolean> {
        console.log('closeAnnoucement', id);
        localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEY.CLOSE_ANNOUNCEMENT, id);

        return true;
    }

    public async isClosedAnnoucement(id: string): Promise<boolean> {
        const closeAnnouncement = localStorage.getItem(
            CONSTANTS.LOCALSTORAGE_KEY.CLOSE_ANNOUNCEMENT
        );

        return closeAnnouncement === String(id);
    }

    public async getLastOne(): Promise<{
        data: AnnoucementDto;
        id: string;
    } | null> {
        const response = await this.get(
            this.buildUrl('announcements?populate=*&pagination[limit]=1&sort=createdAt:desc'),
            true
        );

        if (!response) {
            throw new Error(i18n.t('generic_api_error'));
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            if (!responseJson?.data?.[0]?.attributes) {
                return null;
            }
            return {
                data: responseJson?.data?.[0]?.attributes,
                id: responseJson?.data?.[0]?.id,
            };
        }

        const errorMessage: string = (await response.json()).errors?.[0]?.message;

        throw new Error(errorMessage);
    }

    public async get6Last(): Promise<AnnoucementDto[]> {
        const response = await this.get(
            this.buildUrl('announcements?populate=*&pagination[limit]=6&sort=createdAt:desc'),
            true
        );

        if (!response) {
            throw new Error(i18n.t('generic_api_error'));
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            return (
                responseJson?.data.map((item: any) => {
                    return {
                        ...item?.attributes,
                        id: item?.id,
                    };
                }) || []
            );
        }

        const errorMessage: string = (await response.json()).errors?.[0]?.message;

        throw new Error(errorMessage);
    }

    // public async getAllCategories(): Promise<GetAllCategoriesResponseDto> {
    //     const response = await this.get(this.buildUrl('categories?populate=*'), true);

    //     if (!response) {
    //         throw new Error(i18n.t('generic_api_error'));
    //     }

    //     if (response?.status === 200) {
    //         const responseJson = await response.json();
    //         return responseJson as GetAllCategoriesResponseDto;
    //     }

    //     const errorMessage: string = (await response.json()).errors?.[0]?.message;

    //     throw new Error(errorMessage);
    // }
}

export default AnnoucementService;
