import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './faq-item.scss';
import { theme } from '../../../Style/theme';
import { AddIcon } from '../../../Icons/add-icon';
import { ChevronBottomIcon } from '../../../Icons/chevron-bottom-icon';
import { ChevronTopIcon } from '../../../Icons/chevron-top-icon';

interface FaqItemProps {
    question: string;
    answer: string;
    hasCustomHTML?: {
        answer: boolean;
    };
    type?: 'small' | 'large';
}

const FaqItem: React.FC<FaqItemProps> = ({ question, answer, hasCustomHTML, type = 'large' }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleIsOpen = () => {
        setIsOpen((prev) => !prev);
    };

    const [height, setHeight] = useState(0);
    const ref = useRef<any>(null);

    useEffect(() => {
        setHeight(ref.current.clientHeight + 31);
    });

    return (
        <div className="FaqItem" onClick={handleIsOpen}>
            <div className="top">
                {type === 'small' && !isOpen ? (
                    <ChevronBottomIcon
                        fill={theme['black']}
                        width={24}
                        height={24}
                        className="icon"
                    />
                ) : type === 'small' && isOpen ? (
                    <ChevronTopIcon fill={theme['black']} width={24} height={24} className="icon" />
                ) : null}
                <div className="question text-body-large-primary-font-bold text-element-black-secondary-alt">
                    {t(question)}
                </div>

                {type === 'large' && (
                    <AddIcon
                        fill={theme['black']}
                        width={24}
                        height={24}
                        className={`icon ${isOpen ? '-rotate-45' : '-rotate-0'}`}
                    />
                )}
            </div>

            <div
                className={`bottom ${isOpen ? '-open' : '-close'}`}
                style={{
                    height: isOpen ? height + 'px' : 0,
                }}
            >
                {hasCustomHTML?.answer ? (
                    <div
                        className="text-body answer -html text-element-greys-secondary"
                        dangerouslySetInnerHTML={{ __html: t(answer) }}
                    />
                ) : (
                    <div className="text-body answer text-element-greys-secondary">{t(answer)}</div>
                )}
            </div>

            <div className="fake" ref={ref}>
                {hasCustomHTML?.answer ? (
                    <div
                        className="text-body answer -html"
                        dangerouslySetInnerHTML={{ __html: t(answer) }}
                    />
                ) : (
                    <div className="text-body answer">{t(answer)}</div>
                )}
            </div>
        </div>
    );
};

export default FaqItem;
