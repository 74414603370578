import React from 'react';
import { BlockDto } from '../../../Services/Blog';
import './index.scss';

interface MediaStrapiProps {
    block: BlockDto;
}

const MediaStrapi: React.FC<MediaStrapiProps> = ({ block }) => {
    const url = (block as any)?.file?.data?.attributes?.url;

    return (
        <div className="MediaStrapi img-container">
            <img src={url} alt="strapi img" />
        </div>
    );
};

export default MediaStrapi;
