import FuturOfFashionSection from '../Home/Sections/FuturOfFashion';
import Banner from './Sections/Banner';
import BlogList from './Sections/BlogList';
import './index.scss';

const BlogPage = () => {
    return (
        <div className="BlogPage">
            <Banner />
            <BlogList />
            <FuturOfFashionSection />
        </div>
    );
};

export default BlogPage;
