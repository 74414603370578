import React, { FunctionComponent } from 'react';
import './index.scss';
import Button from '../../../../Components/Button';
import Carousel from '../../../../Components/Carousel';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import CONSTANTS from '../../../../Constants';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import Copydeck from '../../../../i18n/Copydeck';
import WrapperSection from '../../../../Components/WrapperSection';
import CardLeader from '../../../AboutUs/Sections/Leaders/Components/card-leader';

const CompanyLeadership: FunctionComponent = () => {
    const { windowWidth } = useWindowDimensions();
    const navigate = useCustomNavigation();

    const CAROUSEL_BREAKPOINT = 1000;

    return (
        <div className="CompanyLeadership">
            <div className="content">
                <WrapperSection.Title
                    dangerousHTML={Copydeck.homePageIntellectualSectionCompanyLeadership as string}
                    className="title capitalize"
                    htmlTag="h1"
                />

                {windowWidth > CAROUSEL_BREAKPOINT ? (
                    <div className="leaders-container">
                        {CONSTANTS.LEADERS.map((leader) => {
                            return (
                                <CardLeader
                                    isLast={leader.isLast}
                                    leader={leader}
                                    key={leader.fullName}
                                    flip={false}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <Carousel
                        carouselElements={CONSTANTS.LEADERS.sort((a, b) => {
                            if (a.isLast) return -1;
                            if (b.isLast) return 1;

                            return 0;
                        }).map((leader) => (
                            <CardLeader
                                isLast={leader.isLast}
                                leader={leader}
                                key={leader.fullName}
                                flip={false}
                            />
                        ))}
                        centerMode={false}
                        infiniteScroll={false}
                        slidesToShow={3}
                        responsive={[
                            {
                                breakpoint: 1001,
                                settings: {
                                    slidesToShow: 2,
                                },
                            },
                            {
                                breakpoint: 730,
                                settings: {
                                    slidesToShow: 1,
                                },
                            },
                        ]}
                    />
                )}

                <div className="d-flex-justify">
                    <Button
                        onClick={() => navigate(CONSTANTS.SCREEN.ABOUT_US)}
                        text={Copydeck.learnMore}
                        type="black"
                        className="ctas"
                    />
                </div>
            </div>
        </div>
    );
};

export default CompanyLeadership;
