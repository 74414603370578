import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const TimeIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="42" height="41" viewBox="0 0 42 41" {...props} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.0234 3.3833C11.4965 3.3833 3.77344 11.1064 3.77344 20.6333C3.77344 30.1602 11.4965 37.8833 21.0234 37.8833C30.5504 37.8833 38.2734 30.1602 38.2734 20.6333C38.2734 11.1064 30.5504 3.3833 21.0234 3.3833ZM0.773437 20.6333C0.773437 9.44953 9.83967 0.383301 21.0234 0.383301C32.2072 0.383301 41.2734 9.44953 41.2734 20.6333C41.2734 31.8171 32.2072 40.8833 21.0234 40.8833C9.83967 40.8833 0.773437 31.8171 0.773437 20.6333Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.0234 8.1958C21.8519 8.1958 22.5234 8.86737 22.5234 9.6958V19.1333H31.9609C32.7894 19.1333 33.4609 19.8049 33.4609 20.6333C33.4609 21.4617 32.7894 22.1333 31.9609 22.1333H21.0234C20.195 22.1333 19.5234 21.4617 19.5234 20.6333V9.6958C19.5234 8.86737 20.195 8.1958 21.0234 8.1958Z"
                fill={props.fill}
            />
        </svg>
    );
};
