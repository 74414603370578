import React from 'react';

export interface LayoutPolicyTitleProps {
    title: string;
}

const LayoutPolicyTitle: React.FC<LayoutPolicyTitleProps> = ({ title }) => {
    const formattedTitle = title.endsWith('.') ? title.slice(0, -1) : title;
    return <h1 className="LayoutPolicyTitle deprecated-text-headline-medium">{formattedTitle}</h1>;
};

export default LayoutPolicyTitle;
