import React from 'react';

import './index.scss';
import LifeAtSpreeAISectionHero from './Sections/Hero';
import LifeAtSpreeAISectionRemote from './Sections/Remote';
import LifeAtSpreeAISectionInclusionDiversity from './Sections/InclusionDiversity';
import Button from '../../Components/Button';
import Copydeck from '../../i18n/Copydeck';
import CONSTANTS from '../../Constants';
import LifeAtSpreeAIPageSectionBenefits from './Sections/Benefits';
import WrapperSection from '../../Components/WrapperSection';
import useCustomNavigation from '../../Hooks/use-custom-navigation';
import LatestUpdates from '../Blog/id/Sections/LatestUpdates';
import AboutUsCoresValueSectionPage from './Sections/CoresValues';

const LifeAtSpreeAIPage = () => {
    const nav = useCustomNavigation();

    React.useEffect(() => {
        const anchor = window.location.href.split('#')[1];

        if (anchor) {
            const anchorEl = document.getElementById(anchor);
            if (anchorEl) {
                setTimeout(() => {
                    anchorEl.scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
        }
    }, [window.location.pathname]);

    return (
        <div className="LifeAtSpreeAIPage">
            <LifeAtSpreeAISectionHero />
            <div className="container-cores-value">
                <AboutUsCoresValueSectionPage />
            </div>

            <LifeAtSpreeAISectionRemote />

            <div id="benefits">
                <LifeAtSpreeAIPageSectionBenefits />
            </div>

            <LifeAtSpreeAISectionInclusionDiversity />

            <LatestUpdates />

            <div className="container-join-team">
                <WrapperSection.Title htmlTag="h2" className="capitalize">
                    {Copydeck.lifeAtSpreeAipageJoinTheTeamTitle}
                </WrapperSection.Title>

                <button
                    onClick={() => nav(CONSTANTS.SCREEN.CAREERS)}
                    className="btn-delete-default-style"
                >
                    <Button type="primary" text={Copydeck.lifeAtSpreeAipageJoinTheTeamCtaCareers} />
                </button>
            </div>
        </div>
    );
};

export default LifeAtSpreeAIPage;
