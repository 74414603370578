import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const SmartIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="48"
            height="40"
            viewBox="0 0 48 40"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.457 22.4939C14.457 21.6655 15.1286 20.9939 15.957 20.9939C17.7989 20.9939 19.5993 21.5401 21.1308 22.5633C22.6622 23.5866 23.8558 25.041 24.5607 26.7427C25.2655 28.4443 25.4499 30.3167 25.0906 32.1232C24.7313 33.9296 23.8443 35.589 22.542 36.8913C21.2396 38.1937 19.5803 39.0806 17.7738 39.44C15.9674 39.7993 14.0949 39.6149 12.3933 38.91C10.6917 38.2052 9.23724 37.0116 8.21397 35.4801C7.1907 33.9487 6.64453 32.1482 6.64453 30.3064V28.9919C6.64453 28.1635 7.3161 27.4919 8.14453 27.4919C8.97296 27.4919 9.64453 28.1635 9.64453 28.9919V30.3064C9.64453 31.5549 10.0148 32.7753 10.7084 33.8134C11.402 34.8515 12.3879 35.6606 13.5413 36.1384C14.6948 36.6162 15.964 36.7412 17.1885 36.4976C18.413 36.254 19.5378 35.6528 20.4206 34.77C21.3035 33.8872 21.9047 32.7624 22.1482 31.5379C22.3918 30.3134 22.2668 29.0442 21.789 27.8907C21.3112 26.7372 20.5022 25.7514 19.4641 25.0577C18.426 24.3641 17.2055 23.9939 15.957 23.9939C15.1286 23.9939 14.457 23.3223 14.457 22.4939Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.4083 22.5633C27.9397 21.5401 29.7402 20.9939 31.582 20.9939C32.4105 20.9939 33.082 21.6655 33.082 22.4939C33.082 23.3223 32.4105 23.9939 31.582 23.9939C30.3335 23.9939 29.1131 24.3641 28.075 25.0577C27.0369 25.7514 26.2278 26.7372 25.75 27.8907C25.2723 29.0442 25.1473 30.3134 25.3908 31.5379C25.6344 32.7624 26.2356 33.8872 27.1184 34.77C28.0012 35.6528 29.126 36.254 30.3505 36.4976C31.575 36.7412 32.8443 36.6162 33.9977 36.1384C35.1512 35.6606 36.1371 34.8515 36.8307 33.8134C37.5243 32.7753 37.8945 31.5549 37.8945 30.3064V28.9919C37.8945 28.1635 38.5661 27.4919 39.3945 27.4919C40.223 27.4919 40.8945 28.1635 40.8945 28.9919V30.3064C40.8945 32.1482 40.3484 33.9487 39.3251 35.4801C38.3018 37.0116 36.8474 38.2052 35.1458 38.91C33.4441 39.6149 31.5717 39.7993 29.7653 39.44C27.9588 39.0806 26.2995 38.1937 24.9971 36.8913C23.6947 35.589 22.8078 33.9296 22.4485 32.1232C22.0891 30.3167 22.2736 28.4443 22.9784 26.7427C23.6832 25.041 24.8769 23.5866 26.4083 22.5633Z"
                fill={props.fill}
            />
            <path
                d="M12.8326 29.5251H11.2701C9.05713 29.5271 6.91476 28.7462 5.22217 27.3205C3.52957 25.8949 2.39587 23.9164 2.02171 21.7352C1.64754 19.5541 2.05703 17.3109 3.17771 15.4026C4.29839 13.4943 6.05799 12.044 8.14514 11.3084V9.9939C8.14514 7.92189 8.96824 5.93475 10.4334 4.46962C11.8985 3.0045 13.8856 2.1814 15.9576 2.1814C18.0296 2.1814 20.0168 3.0045 21.4819 4.46962C22.947 5.93475 23.7701 7.92189 23.7701 9.9939V30.3064"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
                stroke-linejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.9971 3.40896C26.7435 1.66253 29.1122 0.681396 31.582 0.681396C34.0519 0.681396 36.4205 1.66253 38.167 3.40896C39.9134 5.1554 40.8945 7.52407 40.8945 9.9939V10.3037C42.8756 11.2325 44.5389 12.7418 45.6554 14.643C46.9554 16.8566 47.4304 19.4587 46.9964 21.9888C46.5623 24.519 45.2472 26.814 43.2838 28.4678C41.3204 30.1216 38.8353 31.0274 36.2682 31.0251L36.2695 29.5251V31.0251H36.2682H34.707C33.8786 31.0251 33.207 30.3536 33.207 29.5251C33.207 28.6967 33.8786 28.0251 34.707 28.0251H36.2709C38.1298 28.0268 39.9294 27.3708 41.3512 26.1733C42.773 24.9757 43.7253 23.3138 44.0396 21.4816C44.3539 19.6495 44.0099 17.7652 43.0685 16.1622C42.1271 14.5593 40.6491 13.341 38.8959 12.723C38.2959 12.5115 37.8945 11.9445 37.8945 11.3084V9.9939C37.8945 8.31972 37.2295 6.71411 36.0456 5.53028C34.8618 4.34646 33.2562 3.6814 31.582 3.6814C29.9079 3.6814 28.3022 4.34646 27.1184 5.53028C25.9346 6.71411 25.2695 8.31972 25.2695 9.9939C25.2695 10.8223 24.598 11.4939 23.7695 11.4939C22.9411 11.4939 22.2695 10.8223 22.2695 9.9939C22.2695 7.52407 23.2507 5.1554 24.9971 3.40896Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.9971 3.40896C26.7435 1.66253 29.1122 0.681396 31.582 0.681396C34.0519 0.681396 36.4205 1.66253 38.167 3.40896C39.9134 5.1554 40.8945 7.52407 40.8945 9.9939V10.3037C42.8756 11.2325 44.5389 12.7418 45.6554 14.643C46.9554 16.8566 47.4304 19.4587 46.9964 21.9888C46.5623 24.519 45.2472 26.814 43.2838 28.4678C41.3204 30.1216 38.8353 31.0274 36.2682 31.0251L36.2695 29.5251V31.0251H36.2682H34.707C33.8786 31.0251 33.207 30.3536 33.207 29.5251C33.207 28.6967 33.8786 28.0251 34.707 28.0251H36.2709C38.1298 28.0268 39.9294 27.3708 41.3512 26.1733C42.773 24.9757 43.7253 23.3138 44.0396 21.4816C44.3539 19.6495 44.0099 17.7652 43.0685 16.1622C42.1271 14.5593 40.6491 13.341 38.8959 12.723C38.2959 12.5115 37.8945 11.9445 37.8945 11.3084V9.9939C37.8945 8.31972 37.2295 6.71411 36.0456 5.53028C34.8618 4.34646 33.2562 3.6814 31.582 3.6814C29.9079 3.6814 28.3022 4.34646 27.1184 5.53028C25.9346 6.71411 25.2695 8.31972 25.2695 9.9939C25.2695 10.8223 24.598 11.4939 23.7695 11.4939C22.9411 11.4939 22.2695 10.8223 22.2695 9.9939C22.2695 7.52407 23.2507 5.1554 24.9971 3.40896Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.582 10.0564C32.4105 10.0564 33.082 10.728 33.082 11.5564V12.3376C33.082 13.3902 33.5002 14.3997 34.2445 15.144C34.9887 15.8883 35.9982 16.3064 37.0508 16.3064H37.832C38.6605 16.3064 39.332 16.978 39.332 17.8064C39.332 18.6348 38.6605 19.3064 37.832 19.3064H37.0508C35.2026 19.3064 33.43 18.5722 32.1231 17.2653C30.8162 15.9584 30.082 14.1859 30.082 12.3376V11.5564C30.082 10.728 30.7536 10.0564 31.582 10.0564Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.957 10.0564C16.7855 10.0564 17.457 10.728 17.457 11.5564V12.3376C17.457 14.1859 16.7228 15.9584 15.4159 17.2653C14.109 18.5722 12.3365 19.3064 10.4883 19.3064H9.70703C8.8786 19.3064 8.20703 18.6348 8.20703 17.8064C8.20703 16.978 8.8786 16.3064 9.70703 16.3064H10.4883C11.5409 16.3064 12.5503 15.8883 13.2946 15.144C14.0389 14.3997 14.457 13.3902 14.457 12.3376V11.5564C14.457 10.728 15.1286 10.0564 15.957 10.0564Z"
                fill={props.fill}
            />
        </svg>
    );
};
