import WrapperImage from '../../../../Components/WrapperImage';
import { ArticleDto } from '../../../../Services/Blog';
import Copydeck from '../../../../i18n/Copydeck';
import './index.scss';
import CONSTANTS from '../../../../Constants';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import { theme } from '../../../../Style/theme';
import { Spin } from 'antd';
import { formatDate } from '../../../../Utils/object';
import { useStores } from '../../../../Hooks/use-stores';
import { SupportedLanguage } from '../../../../Stores/LanguageStore';
import { useTranslation } from 'react-i18next';

interface ArticleCardProps {
    article: ArticleDto;
    onClick?: () => void;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article, onClick }) => {
    const nav = useCustomNavigation();
    const onCustomClick = () =>
        nav(CONSTANTS.SCREEN.BLOG_ID, `${article.id}-${article.attributes.slug}`);
    const { languageStore } = useStores();
    const { t } = useTranslation();

    return (
        <div className="ArticleCard" onClick={onClick ? onClick : onCustomClick}>
            <div
                className="wrapper-img"
                style={{
                    backgroundColor:
                        article?.attributes?.category?.data?.attributes?.backgroundColor ||
                        theme['white'],
                }}
            >
                {article?.attributes?.cover?.data?.attributes?.formats?.small?.url ||
                article?.attributes?.cover?.data?.attributes?.url ? (
                    <WrapperImage
                        uniqueSrc={
                            article?.attributes?.cover?.data?.attributes?.formats?.small?.url ||
                            article?.attributes?.cover?.data?.attributes?.url
                        }
                        alt={Copydeck.blogPageBannerImg1Alt}
                        className="left-side-image"
                    />
                ) : (
                    <div className="spiner-wrapper">
                        <Spin size="large" />
                    </div>
                )}
            </div>

            <div className="card-content">
                {article?.attributes?.category?.data?.attributes.name ? (
                    <p className="category text-body-medium-primary-font">
                        {article?.attributes?.category?.data?.attributes.name || ''}
                    </p>
                ) : (
                    <p className="fake-category"></p>
                )}

                <h2 className="title text-body-medium-primary-font-bold">
                    {article?.attributes?.title}
                </h2>

                {article?.attributes?.authors?.data.length > 0 ? (
                    <div className="author">
                        <span>{Copydeck.blogPageBannerBy}</span>{' '}
                        <span>
                            {article?.attributes?.authors?.data
                                ?.map((item) => item.attributes.name)
                                .join(', ')}
                        </span>
                    </div>
                ) : (
                    <div className="author">
                        <span>{Copydeck.blogPageBannerBy}</span> <span>{t('unknow')}</span>
                    </div>
                )}

                <div className="date">
                    {formatDate(
                        article?.attributes?.publishedAt,
                        languageStore.currentLanguage || SupportedLanguage.EN
                    )}
                </div>
            </div>
        </div>
    );
};

export default ArticleCard;
