import React from 'react';
import './card-value.scss';

interface CardValueProps {
    icon: () => JSX.Element;
    title: string;
    description: string;
    className?: string;
}

const CardValue: React.FC<CardValueProps> = ({ icon, title, description, className }) => {
    return (
        <div className={`CardValue ${className}`}>
            <div className="icon">{icon()}</div>

            <div className="title text-body-large-primary-font-bold text-surface-grey-opposite">
                {title}
            </div>

            <div className="description text-body">{description}</div>
        </div>
    );
};

export default CardValue;
