import React, { useEffect } from 'react';
import './index.scss';
import Button from '../../../../Components/Button';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../../../../Constants';
import VideoMember from './Components/video-member';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import WrapperSection from '../../../../Components/WrapperSection';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import WrapperDevice, { Device } from '../../../../Components/WrapperDevice';

const LifeAtSpreeAISectionHero = () => {
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();
    const nav = useCustomNavigation();
    const [isSlowComputer, setIsSlowComputer] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const WIDTH_SMALL = 850;
    const WIDTH_LARGE = 1050;

    useEffect(() => {
        if (isLoaded) {
            return;
        }

        try {
            if (!window?.performance) return;
            if (!window?.performance?.timing) return;
            if (!window?.performance?.timing?.domContentLoadedEventEnd) return;
            if (!window?.performance?.timing?.navigationStart) return;

            const isSlow =
                window?.performance?.timing?.domContentLoadedEventEnd -
                    window?.performance?.timing?.navigationStart >
                3000;

            setIsSlowComputer(isSlow);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoaded(true);
        }
    }, [window?.performance]);

    return (
        <div className="LifeAtSpreeAISectionHero">
            <WrapperSection.Title className="title capitalize">
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('LifeAtSpreeAIPage.life_at_spree_ai_title'),
                    }}
                />
            </WrapperSection.Title>

            {isSlowComputer ? (
                <>
                    <WrapperDevice devices={[Device.Mobile]}>
                        <div className="wrapper-meeting-team">
                            <VideoMember
                                className="card-top-left"
                                requireSrc={require('./../../../../Assets/MP4/members/mobile/member-1-mobile.mp4')}
                                country="Milan"
                            />
                            <VideoMember
                                className="card-top-right"
                                requireSrc={require('./../../../../Assets/MP4/members/mobile/member-2-mobile.mp4')}
                                requireSrcWebM={require('./../../../../Assets/MP4/members/mobile/member-2-mobile.webm')}
                                country="San Jose"
                            />
                            <VideoMember
                                className="card-bottom-left"
                                requireSrc={require('./../../../../Assets/MP4/members/mobile/member-4-mobile.mp4')}
                                requireSrcWebM={require('./../../../../Assets/MP4/members/mobile/member-4-mobile.webm')}
                                country="Cairo"
                            />
                            <VideoMember
                                className="card-bottom-right"
                                requireSrc={require('./../../../../Assets/MP4/members/mobile/member-3-mobile.mp4')}
                                requireSrcWebM={require('./../../../../Assets/MP4/members/mobile/member-3-mobile.webm')}
                                country="Tulsa"
                            />
                        </div>
                    </WrapperDevice>
                    <WrapperDevice devices={[Device.Tablet, Device.Desktop]}>
                        <div className="wrapper-meeting-team">
                            <VideoMember
                                className="card-top-left"
                                requireSrc={require('./../../../../Assets/MP4/member-1.mp4')}
                                requireSrcWebM={require('./../../../../Assets/MP4/member-1.webm')}
                                country="Milan"
                            />
                            {windowWidth > WIDTH_SMALL ? (
                                <VideoMember
                                    className={
                                        windowWidth < WIDTH_LARGE ? 'card-top-right' : 'none'
                                    }
                                    requireSrc={require('./../../../../Assets/MP4/member-2.mp4')}
                                    requireSrcWebM={require('./../../../../Assets/MP4/member-2.webm')}
                                    country="Orange County"
                                />
                            ) : null}
                            <VideoMember
                                className={
                                    windowWidth < WIDTH_SMALL || windowWidth > WIDTH_LARGE
                                        ? 'card-top-right'
                                        : 'none'
                                }
                                requireSrc={require('./../../../../Assets/MP4/member-3.mp4')}
                                requireSrcWebM={require('./../../../../Assets/MP4/member-3.webm')}
                                country="Tulsa"
                            />
                            {windowWidth > WIDTH_SMALL ? (
                                <>
                                    <VideoMember
                                        requireSrc={require('./../../../../Assets/MP4/member-4.mp4')}
                                        requireSrcWebM={require('./../../../../Assets/MP4/member-4.mp4')}
                                        country="Cairo"
                                    />
                                    <VideoMember
                                        country="San Diego"
                                        requireSrc={require('./../../../../Assets/MP4/member-5.mp4')}
                                        requireSrcWebM={require('./../../../../Assets/MP4/member-5.webm')}
                                    />
                                    <VideoMember
                                        requireSrc={require('./../../../../Assets/MP4/member-6.mp4')}
                                        requireSrcWebM={require('./../../../../Assets/MP4/member-6.webm')}
                                        country="Reno"
                                    />
                                </>
                            ) : null}
                            <VideoMember
                                className="card-bottom-left"
                                requireSrc={require('./../../../../Assets/MP4/member-7.mp4')}
                                requireSrcWebM={require('./../../../../Assets/MP4/member-7.webm')}
                                country="Cairo"
                            />
                            {windowWidth > WIDTH_LARGE ? (
                                <VideoMember
                                    requireSrc={require('./../../../../Assets/MP4/member-8.mp4')}
                                    requireSrcWebM={require('./../../../../Assets/MP4/member-8.webm')}
                                    country="Ha Noi"
                                />
                            ) : null}
                            <VideoMember
                                className="card-bottom-right"
                                requireSrc={require('./../../../../Assets/MP4/member-9.mp4')}
                                requireSrcWebM={require('./../../../../Assets/MP4/member-9.webm')}
                                country="Orange County"
                            />
                        </div>
                    </WrapperDevice>
                </>
            ) : (
                <>
                    <WrapperDevice devices={[Device.Mobile]} breakpointMobile={800}>
                        <div className="wrapper-meeting-team">
                            <VideoMember
                                className="card-top-left"
                                requireSrc={require('./../../../../Assets/MP4/members/mobile/member-1-mobile.mp4')}
                                country="San Jose"
                            />
                            <VideoMember
                                className="card-top-right"
                                requireSrc={require('./../../../../Assets/MP4/members/mobile/member-2-mobile.mp4')}
                                requireSrcWebM={require('./../../../../Assets/MP4/members/mobile/member-2-mobile.webm')}
                                country="Orange County"
                            />
                            <VideoMember
                                className="card-bottom-left"
                                requireSrc={require('./../../../../Assets/MP4/members/mobile/member-4-mobile.mp4')}
                                requireSrcWebM={require('./../../../../Assets/MP4/members/mobile/member-4-mobile.webm')}
                                country="Cairo"
                            />
                            <VideoMember
                                className="card-bottom-right"
                                requireSrc={require('./../../../../Assets/MP4/members/mobile/member-3-mobile.mp4')}
                                requireSrcWebM={require('./../../../../Assets/MP4/members/mobile/member-3-mobile.webm')}
                                country="Tulsa"
                            />
                        </div>
                    </WrapperDevice>

                    <WrapperDevice devices={[Device.Tablet, Device.Desktop]} breakpointMobile={800}>
                        <div className="wrapper-meeting-team -full">
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-1.webm')}
                                country="Los Angeles"
                                className="card-top-left"
                            />
                            <VideoMember
                                requireSrc={require('./../../../../Assets/MP4/members/desktop/member-2.mp4')}
                                country="San Jose"
                            />
                            <VideoMember
                                requireSrc={require('./../../../../Assets/MP4/members/desktop/member-3.mp4')}
                                country="Orange County"
                                className={windowWidth < 1067 ? 'card-top-right' : 'none'}
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-4.mp4')}
                                country="Orange County"
                            />
                            <VideoMember
                                country="Los Angeles"
                                className="card-top-right"
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-5.webm')}
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-6.webm')}
                                country="Orange County"
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-7.webm')}
                                country="Atlanta"
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-8.webm')}
                                country="Colombo"
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-9.webm')}
                                country="San Diego"
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-10.webm')}
                                country="Cairo"
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-11.webm')}
                                country="Cairo"
                                className={windowWidth > 1067 ? 'card-bottom-left' : 'none'}
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-12.webm')}
                                country="Hyderabad"
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-13.webm')}
                                country="Reno"
                                className={windowWidth < 1067 ? 'card-bottom-left' : 'none'}
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-14.webm')}
                                country="Orange County"
                            />
                            <VideoMember
                                requireSrcWebM={require('./../../../../Assets/MP4/members/desktop/member-15.mp4')}
                                country="Austin"
                                className="card-bottom-right"
                            />
                        </div>
                    </WrapperDevice>
                </>
            )}

            <div className="our-mission">
                <div className="left">
                    <WrapperSection.Title>
                        {t('LifeAtSpreeAIPage.our_mission')}
                    </WrapperSection.Title>
                </div>

                <div className="right">
                    <p className="text-body">{t('LifeAtSpreeAIPage.our_mission_p1')} </p>

                    <br />

                    <p className="text-body">{t('LifeAtSpreeAIPage.our_mission_p2')} </p>

                    <Button
                        className="cta-join-us"
                        text={t('join_us')}
                        type="primary"
                        fullInMobile={false}
                        onClick={() => nav(CONSTANTS.SCREEN.CAREERS)}
                    />
                </div>
            </div>
        </div>
    );
};

export default LifeAtSpreeAISectionHero;
