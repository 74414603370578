import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WrapperSectionTitle from '../../Components/WrapperSection/title';
import UseServices from '../../Hooks/use-services';
import { useWindowDimensions } from '../../Hooks/use-window-dimensions';

import { JobListing, JobLocation } from '../../Services/Careers';

import './index.scss';

const CareerPage = () => {
    const [jobs, setJobs] = useState<JobListing[]>([]);
    const { t } = useTranslation();
    const { careerService } = UseServices();
    const { windowWidth } = useWindowDimensions();
    const MOBILE_BREAKPOINT = 620;

    const handleRowClick = (job: JobListing) => {
        window.open(job.url, '_blank');
    };

    const formatLocation = (location: JobLocation) => {
        let locationString = '';
        if (location.city) {
            locationString += `${location.city}, `;
        }
        if (location.region) {
            locationString += `${location.region}, `;
        }
        locationString += location.country;

        return locationString.trim().replace(/,\s*$/, ''); // Clean up trailing commas and spaces
    };

    useEffect(() => {
        careerService
            .getJobsListings()
            .then((res: any) => {
                setJobs(res.jobs);
            })
            .catch((error) => {
                console.log(error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return windowWidth > MOBILE_BREAKPOINT ? (
        <div className="CareerPage">
            <WrapperSectionTitle htmlTag="h1">{t('header_nav_careers')}</WrapperSectionTitle>

            <table>
                <thead>
                    <tr>
                        <th className="text-body-bold">{t('Careers.job_title')}</th>
                        <th className="text-body-bold text-element-greys-secondary-alt">
                            {t('Careers.work_mode')}
                        </th>
                        <th className="text-body-bold">{t('Careers.location')}</th>
                        <th className="text-body-bold">{t('Careers.employment_type')}</th>
                    </tr>
                </thead>
                <tbody>
                    {jobs.map((job) => (
                        <tr key={job.shortcode} onClick={() => handleRowClick(job)}>
                            <td className="title text-body-bold text-surface-brand-rest-primary job-title">
                                {job.title}
                            </td>
                            <td className="text-element-greys-tertiary text-weight-600 job-remote">
                                {job.telecommuting ? t('Careers.remote') : t('Careers.hybrid')}
                            </td>
                            <td className="text-body job-location">
                                {formatLocation(job.locations[0])}
                            </td>

                            <td className="text-body job-type">{job.employment_type}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    ) : (
        <div className="CareerMobile">
            <WrapperSectionTitle htmlTag="h1">{t('header_nav_careers')}</WrapperSectionTitle>
            {jobs.map((job) => (
                <div key={job.shortcode} className="job" onClick={() => handleRowClick(job)}>
                    <p className="job-title text-body-bold text-surface-brand-rest-primary">
                        {job.title}
                    </p>
                    <div className="job-info">
                        <p className="job-remote text-element-greys-secondary-alt text-body-small text-weight-600">
                            {job.telecommuting ? t('Careers.remote') : t('Careers.hybrid')}
                        </p>
                        <p className="job-location text-body-small">
                            {formatLocation(job.locations[0])}
                        </p>
                        <p className="job-type text-body-small">{job.employment_type}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default CareerPage;
