import React from 'react';

export interface LayoutPolicyListProps {
    text: string;
}

const LayoutPolicyList: React.FC<LayoutPolicyListProps> = ({ text }) => {
    const split = text.split('<br />');

    return (
        <ul>
            {split.map((x) => (
                <li
                    className="deprecated-text-body-large"
                    key={x}
                    dangerouslySetInnerHTML={{ __html: x.replace(/ +(?= )/g, '') }}
                />
            ))}
        </ul>
    );
};

export default LayoutPolicyList;
