import { useTranslation } from 'react-i18next';
import Form from '../../Components/Form';
import './index.scss';
import UseServices from '../../Hooks/use-services';
import AnimatedEllipses from './Components/AnimatedEllipses';
import WrapperSection from '../../Components/WrapperSection';
import Copydeck from '../../i18n/Copydeck';

const ContactUs = () => {
    const { t } = useTranslation();
    const { contactUsService } = UseServices();

    const onSubmit = async (values: any) => {
        if (!values) {
            return false;
        }

        const result = await contactUsService.sendContactUsMessage({
            first_name: values?.first_name || '',
            last_name: values.last_name,
            company_name: values.company_name,
            phone: values.phone_number,
            email: values.email,
            subject: values.subject,
            message: values.message,
        });

        return result || false;
    };

    return (
        <div className="ContactUs">
            <AnimatedEllipses className="ellipses" />
            <WrapperSection.Title className="text-white">{t('contact_us')}</WrapperSection.Title>

            <div className="subTitle">
                <div
                    className="text-white text-body"
                    dangerouslySetInnerHTML={{
                        __html: Copydeck.contactUsPageSubtitleDirectContact1,
                    }}
                />
                <div
                    className="text-white text-body"
                    dangerouslySetInnerHTML={{
                        __html: Copydeck.contactUsPageSubtitleDirectContact2,
                    }}
                />
            </div>

            <div className="form-container">
                <Form
                    onSubmit={onSubmit}
                    validation={[
                        {
                            required: true,
                            inputName: 'first_name',
                        },
                        {
                            required: true,
                            inputName: 'last_name',
                        },
                        {
                            required: false,
                            inputName: 'company_name',
                        },
                        {
                            required: false,
                            inputName: 'phone_number',
                        },
                        {
                            required: true,
                            inputName: 'email',
                            isEmail: true,
                        },
                        {
                            required: true,
                            inputName: 'subject',
                        },
                        {
                            required: true,
                            inputName: 'message',
                        },
                    ]}
                >
                    <div className="side-by-side">
                        <Form.BasicInput label={t('first_name')} name="first_name" />
                        <Form.BasicInput label={t('last_name')} name="last_name" />
                        <Form.BasicInput label={t('company_name')} name="company_name" />
                    </div>

                    <div className="side-by-side">
                        <Form.BasicInput label={t('phone_number')} name="phone_number" />
                        <Form.BasicInput label={t('email')} name="email" type="email" />
                    </div>

                    <Form.BasicInput label={t('subject')} name="subject" />

                    <Form.TextArea label={t('message')} name="message" />

                    <Form.Submit />
                </Form>
            </div>
        </div>
    );
};

export default ContactUs;
