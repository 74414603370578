import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const IdeeIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="51"
            height="50"
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.4609 45.2439C16.4609 44.4155 17.1325 43.7439 17.9609 43.7439H33.5859C34.4144 43.7439 35.0859 44.4155 35.0859 45.2439C35.0859 46.0723 34.4144 46.7439 33.5859 46.7439H17.9609C17.1325 46.7439 16.4609 46.0723 16.4609 45.2439Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.7604 8.58805C31.3129 6.91064 28.3993 6.04655 25.433 6.1184L25.4313 6.11844C17.7852 6.29482 11.6063 12.6701 11.6486 20.3234C11.6591 22.4538 12.1522 24.5551 13.0908 26.4676C14.0292 28.3795 15.3886 30.0541 17.0669 31.3654C17.8114 31.9452 18.4141 32.6871 18.829 33.5347C19.244 34.3826 19.4602 35.3139 19.4612 36.2579L19.4612 37.4313C19.4612 37.4479 19.4678 37.4638 19.4795 37.4755C19.4912 37.4873 19.5071 37.4938 19.5237 37.4938H32.0237C32.0403 37.4938 32.0561 37.4873 32.0679 37.4755C32.0796 37.4638 32.0862 37.4479 32.0862 37.4313V36.2595L32.0862 36.2531C32.0902 35.3048 32.3101 34.3699 32.7292 33.5193C33.1476 32.6701 33.7535 31.9272 34.5011 31.3466C36.8332 29.5145 38.5294 26.9948 39.3494 24.1447C40.1698 21.2932 40.0714 18.2558 39.0682 15.4634C38.065 12.671 36.2079 10.2655 33.7604 8.58805ZM25.3613 3.11926C28.9572 3.03234 32.4893 4.07994 35.4564 6.11346C38.4237 8.14714 40.6752 11.0636 41.8915 14.4491C43.1078 17.8345 43.2271 21.5171 42.2325 24.9742C41.2379 28.4312 39.1798 31.4873 36.3504 33.7088L36.3432 33.7144C35.9537 34.0164 35.6381 34.4031 35.4203 34.8451C35.203 35.2864 35.0887 35.7712 35.0862 36.263V37.4313C35.0862 38.2436 34.7635 39.0225 34.1892 39.5969C33.6149 40.1712 32.8359 40.4938 32.0237 40.4938H19.5237C18.7114 40.4938 17.9325 40.1712 17.3582 39.5969C16.7838 39.0225 16.4612 38.2436 16.4612 37.4313V36.2602C16.4605 35.7726 16.3488 35.2916 16.1344 34.8536C15.9199 34.4154 15.6084 34.0319 15.2234 33.7322L15.2213 33.7306C13.1853 32.14 11.5361 30.1088 10.3977 27.7894C9.25947 25.4703 8.6615 22.9234 8.6487 20.34M25.3613 3.11926C16.0745 3.33396 8.59783 11.0566 8.6487 20.34L25.3613 3.11926Z"
                fill={props.fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.857 10.6197C25.9947 9.80274 26.7685 9.25209 27.5854 9.38974C32.1228 10.1543 35.8591 13.8966 36.6276 18.4307C36.766 19.2475 36.2161 20.0219 35.3993 20.1603C34.5826 20.2987 33.8082 19.7488 33.6698 18.9321C33.1141 15.6537 30.362 12.8999 27.0869 12.348C26.27 12.2104 25.7194 11.4366 25.857 10.6197Z"
                fill={props.fill}
            />
        </svg>
    );
};
