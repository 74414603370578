import React, { useCallback } from 'react';
import './button-partner-access-dashboard.scss';
import CONSTANTS from '../../../../Constants';
import { useStores } from '../../../../Hooks/use-stores';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import { ChevronRightIcon } from '../../../../Icons/chevron-right-icon';
import { theme } from '../../../../Style/theme';

interface ButtonPartnerAccessDashboardProps {
    label: string;
    icon: React.ReactNode;
    onClose?: () => void;
}

const ButtonPartnerAccessDashboard: React.FC<ButtonPartnerAccessDashboardProps> = ({
    label,
    icon,
    onClose,
}) => {
    const nav = useCustomNavigation();
    const { userStore } = useStores();

    const onClick = useCallback(() => {
        if (onClose) {
            onClose();
        }

        setTimeout(() => {
            if (window.location.pathname === CONSTANTS.SCREEN.DASHBOARD) {
                window.location.reload();
                return;
            }

            if (userStore.isLogin()) {
                nav(CONSTANTS.SCREEN.DASHBOARD);
                return;
            }

            nav(CONSTANTS.SCREEN.CREATE_ACCOUNT);
        }, 50);
    }, []);

    return (
        <div className="ButtonPartnerAccessDashboard">
            <div className="button btn-delete-default-style" onClick={onClick}>
                <div className="button-icon">{icon}</div>
                <div className="button-label text-body-small-primary-font-bold text-white">
                    {label}
                </div>
                <ChevronRightIcon fill={theme['white']} width={16} height={16} />
            </div>
        </div>
    );
};

export default ButtonPartnerAccessDashboard;
