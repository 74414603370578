import './index.scss';
import Copydeck from '../../../../i18n/Copydeck';
import tryonImage from '../../../../Assets/Img/Home/tryon.webp';
import onlineInstoreImage from '../../../../Assets/Img/Home/online_instore.webp';
import happyAiImage from '../../../../Assets/Img/Home/happy-ai.webp';
import increaseSalesImage from '../../../../Assets/Img/Home/increase-sales.webp';
import Button from '../../../../Components/Button';
import CONSTANTS from '../../../../Constants';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import WrapperSection from '../../../../Components/WrapperSection';
import SmileyIcon from '../../../../Icons/smiley-icon';
import { ChartIcon } from '../../../../Icons/chart-icon';
import { ShoppingBagIcon } from '../../../../Icons/shopping-bag-icon';
import { ShirtIcon } from '../../../../Icons/shirt-icon';

const Benefits = () => {
    const benefits = [
        {
            image: tryonImage,
            text: Copydeck.homePageBenefitsSectionB1,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
            icon: ShirtIcon,
        },
        {
            image: onlineInstoreImage,
            text: Copydeck.homePageBenefitsSectionB3,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
            icon: ShoppingBagIcon,
        },
        {
            image: happyAiImage,
            text: Copydeck.homePageBenefitsSectionB4,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
            icon: SmileyIcon,
        },
        {
            image: increaseSalesImage,
            text: Copydeck.homePageBenefitsSectionB2,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
            icon: ChartIcon,
        },
    ];

    const nav = useCustomNavigation();

    const ourProductSOnClick = () => {
        nav(CONSTANTS.SCREEN.WHAT_IS_SPREEAI);
    };

    return (
        <div className="Benefits">
            <WrapperSection.Title htmlTag="h1">
                {Copydeck.homePageBenefitsSectionBenefitSectionTitle}
            </WrapperSection.Title>

            <ul className="benefits">
                {benefits.map((benefit, index) => {
                    const IconComponent = benefit.icon;

                    return (
                        <li key={index} className="benefit">
                            <div
                                className="benefit-wrapper-icon"
                                style={{
                                    backgroundColor: benefit.backgroundColor,
                                }}
                            >
                                <IconComponent fill={benefit.color} width={40} height={40} />
                            </div>

                            <div className="benefit-description text-body">{benefit.text}</div>
                        </li>
                    );
                })}
            </ul>

            <Button
                type="black"
                text={Copydeck.homePageBenefitsSectionBenefitOurProductBtn}
                onClick={ourProductSOnClick}
                className="cta"
                fullInMobile={false}
            />
        </div>
    );
};

export default Benefits;
