import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import UseServices from '../../../../../Hooks/use-services';
import { ArticleDto } from '../../../../../Services/Blog';
import './index.scss';
import Copydeck from '../../../../../i18n/Copydeck';
import WrapperSectionTitle from '../../../../../Components/WrapperSection/title';
import RenderStrapiBlocks from '../../../../../Components/RenderStrapiBlocks';
import BreadCrumb from '../../../../../Components/BreadCrumb';
import ClockIcon from '../../../../../Icons/ClockIcon';
import { calculateReadingTime, formatDate } from '../../../../../Utils/object';
import { LinkedinIcon } from '../../../../../Icons/linkedin-icon';
import { TwitterIcon } from '../../../../../Icons/twitter-icon';
import EnvelopeIcon from '../../../../../Icons/envelope-icon';
import { HyperlinkIcon } from '../../../../../Icons/hyperlink-icon';
import { useStores } from '../../../../../Hooks/use-stores';
import { SupportedLanguage } from '../../../../../Stores/LanguageStore';
import { Helmet } from 'react-helmet-async';
import WrapperSection from '../../../../../Components/WrapperSection';
import { theme } from '../../../../../Style/theme';
import { useRulesOfThrees } from '../../../../../Hooks/use-rules-of-threes';

const Article = () => {
    const { blogService } = UseServices();
    const { loadingStore, languageStore } = useStores();
    const [articleId, setArticleId] = useState<string | null>(null);
    const [article, setArticle] = useState<ArticleDto | null>(null);
    const [video, setVideo] = useState<{
        rawData?: { html: string };
    } | null>(null);
    const [readingTime, setReadingTime] = useState<number>(0);
    const { t } = useTranslation();
    const height = useRulesOfThrees(1100);

    const getDetailOnArticle = useCallback(async () => {
        loadingStore.addLoading();
        try {
            if (!articleId) {
                toast.error(t('BlogPage.fetch_error') as string);
                return;
            }

            const response = await blogService.getOneArticle(articleId);
            if (!response) {
                toast.error(t('BlogPage.fetch_error') as string);
                return;
            }

            setArticle(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            loadingStore.removeLoading();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articleId]);

    const getArticleId = useCallback(async () => {
        try {
            const currentUrl = window.location.href;

            const url = new URL(currentUrl);
            const { pathname } = url;

            if (!pathname) {
                toast.error(t('BlogPage.fetch_error') as string);
                return;
            }

            const indexOfLastSlash = pathname.lastIndexOf('/');
            const lastSlug = pathname.substring(indexOfLastSlash + 1);
            const extractArticleId = lastSlug.split('-')[0];

            if (!extractArticleId) {
                toast.error(t('BlogPage.fetch_error') as string);
                return;
            }

            setArticleId(extractArticleId);
        } catch (error) {
            console.error(error);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getArticleId();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!articleId) {
            return;
        }

        getDetailOnArticle();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articleId]);

    useEffect(() => {
        if (!article) {
            return;
        }

        const blocks = article.attributes.blocks;
        let numberOfCharacters = 0;

        blocks.forEach((block) => {
            if (block.__component === 'shared.rich-text') {
                numberOfCharacters += block.body.length;
            }
        });

        setReadingTime(calculateReadingTime(numberOfCharacters));
        setVideo(JSON.parse(article.attributes.oembed));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article?.id]);

    const onClickShare = (type: 'linkedin' | 'twitter' | 'hyperlink' | 'mail') => {
        const textToWrite = `${Copydeck.blogPagePlaceholderShared}\n\n ${window.location.href} \n\n`;

        if (type === 'twitter') {
            const link = `https://twitter.com/intent/tweet?text=${window.location.href}`;

            window.open(link, '_blank');
        }

        if (type === 'linkedin') {
            const link = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`;

            window.open(link, '_blank');
        }

        if (type === 'mail') {
            const subject = article?.attributes?.title || Copydeck.blogPagePlaceholderShared;
            const link = `mailto:?subject=${subject}&body=${textToWrite}`;

            window.open(link, '_blank');
        }

        if (type === 'hyperlink') {
            const link = window.location.href;

            navigator.clipboard.writeText(link);
            toast.success(t('BlogPage.link_copied') as string);
        }
    };

    const onClickBack = () => {
        window.history.back();
    };

    if (!article) {
        return null;
    }

    return (
        <div className="Article">
            <Helmet>
                {/* Standard metadata tags */}
                <title>{article?.attributes?.title}</title>
                <meta name="description" content={article?.attributes?.description || 'none'} />
                <meta
                    name="image"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />
                <meta
                    name="image:secure_url"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />

                {/* End standard metadata tags */}

                {/* Facebook tags */}
                <meta property="og:type" content={article?.attributes?.title} />
                <meta property="og:title" content={article?.attributes?.title} />
                <meta
                    name="og:image"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />
                <meta
                    property="og:image:secure_url"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />

                <meta
                    property="og:description"
                    content={article?.attributes?.description || 'none'}
                />
                {/* End Facebook tags */}

                {/* Twitter tags */}
                <meta name="twitter:creator" content={article?.attributes?.title} />
                <meta name="twitter:card" content={article?.attributes?.description || 'none'} />
                <meta name="twitter:title" content={article?.attributes?.title} />
                <meta
                    name="twitter:image"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />
                <meta
                    name="twitter:image:secure_url"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />

                <meta
                    name="twitter:description"
                    content={article?.attributes?.description || 'none'}
                />
                {/* End Twitter tags */}
            </Helmet>

            <div className="content-header btn-delete-default-style" onClick={onClickBack}>
                <div
                    className="wrapper-video"
                    style={{
                        height,
                    }}
                >
                    <img
                        className="img"
                        src={article?.attributes?.cover?.data?.attributes?.formats?.medium?.url}
                        alt={article?.attributes?.title}
                    />
                </div>

                <div className="overlay" />

                <div className="wrapper-content-info">
                    <div className="content-info">
                        {article?.attributes?.category?.data?.attributes.name && (
                            <div className="category">
                                <div className="text-white text-body-medium-primary-font">
                                    {article?.attributes?.category?.data?.attributes.name || 'none'}
                                </div>
                            </div>
                        )}

                        <WrapperSection.Title className="text-white">
                            {article?.attributes?.title}
                        </WrapperSection.Title>

                        {(article?.attributes?.authors?.data?.length || 0) > 0 && (
                            <div className="text-white text-body">
                                {Copydeck.blogPageBannerBy}:{' '}
                                {article?.attributes?.authors?.data
                                    ?.map((author) => author.attributes.name)
                                    .join(', ')}
                            </div>
                        )}
                        {article?.attributes?.publishedAt && (
                            <p className="text-white text-body">
                                {formatDate(
                                    article?.attributes?.publishedAt || '',
                                    languageStore.currentLanguage || SupportedLanguage.EN
                                )}
                            </p>
                        )}
                        <div className="text-white wrapper-time text-body">
                            <ClockIcon fill={theme['white']} />
                            <span className="text-body text-white">
                                {readingTime} {t('min')}
                            </span>
                        </div>
                        <div className="wrapper-icon-share">
                            <LinkedinIcon
                                fillSecond="white"
                                fill="black"
                                onClick={() => onClickShare('linkedin')}
                            />
                            <TwitterIcon
                                width={18}
                                fill="white"
                                height={18}
                                onClick={() => onClickShare('twitter')}
                            />
                            <EnvelopeIcon fill="white" onClick={() => onClickShare('mail')} />
                            <HyperlinkIcon
                                fill="white"
                                width={24}
                                height={24}
                                onClick={() => onClickShare('hyperlink')}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-body">
                <BreadCrumb
                    currentFilter={{
                        value: article?.attributes?.category?.data?.id || 'all',
                        label: article?.attributes?.category?.data?.attributes?.name || 'All',
                    }}
                />

                <WrapperSectionTitle>{article?.attributes?.title}</WrapperSectionTitle>

                <div className="content">
                    {video?.rawData?.html && (
                        <div
                            className="container-embed"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: video?.rawData?.html,
                            }}
                        />
                    )}

                    <RenderStrapiBlocks blocks={article.attributes.blocks} />
                </div>
            </div>
        </div>
    );
};

export default Article;
