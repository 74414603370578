import { useCallback, useEffect, useState } from 'react';
import UseServices from '../../../../Hooks/use-services';
import { AnnoucementDto } from '../../../../Services/Annoucement';
import ImageOrVideo from '../../../../Components/ImageOrVideo';
import WrapperSectionTitle from '../../../../Components/WrapperSection/title';
import './index-mobile.scss';
import { ChevronLeftIcon } from '../../../../Icons/chevron-left-icon';
import { ChevronRightIcon } from '../../../../Icons/chevron-right-icon';
import { theme } from '../../../../Style/theme';
import { PlayIcon } from '../../../../Icons/play-icon';
import { PauseIcon } from '../../../../Icons/pause-icon';
import Copydeck from '../../../../i18n/Copydeck';
import AnnoucementTitle from '../../../../Layout/Components/annoucement-title';
import useAnnoucementLearnMore from '../../../../Hooks/use-annoucement-learn-more';

const AnnoucementSectionMobile = () => {
    const { annoucementService } = UseServices();
    const [currentAnnoucement, setCurrentAnnoucement] = useState<AnnoucementDto | null>(null);
    const [previousAnnoucement, setPreviousAnnoucement] = useState<AnnoucementDto | null>(null);
    const [annoucements, setAnnoucements] = useState<AnnoucementDto[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPause, setIsPause] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const onClickLearnMore = useAnnoucementLearnMore();
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    const getLast9Annoucement = useCallback(async () => {
        try {
            const annoucements = await annoucementService.get6Last();
            setAnnoucements(annoucements);
            setCurrentAnnoucement(annoucements[0]);

            setTimeout(() => {
                const video = document.querySelector('.video') as HTMLVideoElement;
                if (video) {
                    try {
                        video?.play();
                    } catch (error) {
                        console.error(error);
                    }
                }
            }, 1000);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const handleAnnoucement = (newIndex: number) => {
        if (isTransitioning) return;

        setIsTransitioning(true);
        setPreviousAnnoucement(currentAnnoucement);

        setTimeout(() => {
            setCurrentAnnoucement(annoucements[newIndex]);
            setCurrentIndex(newIndex);
            setIsTransitioning(false);
        }, 450);
    };

    const onClickOnItem = (index: number) => {
        handleAnnoucement(index);

        setTimeout(() => {
            const video = document.querySelector('.video') as HTMLVideoElement;
            if (video) {
                try {
                    video?.play();
                } catch (error) {
                    console.error(error);
                }
            }
        }, 1000);
    };

    const onClickOnDot = (index: number) => {
        onClickOnItem(index);
    };

    const onClickNext = () => {
        const nextIndex = (currentIndex + 1) % annoucements.length;
        onClickOnItem(nextIndex);
    };

    const onClickPrev = () => {
        const prevIndex = (currentIndex - 1 + annoucements.length) % annoucements.length;
        onClickOnItem(prevIndex);
    };

    const onClickPlay = () => {
        const video = document.querySelector('.video') as HTMLVideoElement;

        if (video.paused) {
            video.play();
            setIsPause(false);
        } else {
            video.pause();
            setIsPause(true);
        }
    };

    useEffect(() => {
        getLast9Annoucement();
    }, []);

    useEffect(() => {
        const video = document.querySelector('.video') as HTMLVideoElement;
        if (video) {
            try {
                video?.play();
            } catch (error) {
                console.error(error);
            }
        }
    }, [currentAnnoucement]);

    const handleSwipe = () => {
        if (touchStart - touchEnd > 75) {
            onClickNext();
        }

        if (touchStart - touchEnd < -75) {
            onClickPrev();
        }
    };

    const onTouchStart = (e: React.TouchEvent) => {
        setTouchEnd(0);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e: React.TouchEvent) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const onTouchEnd = () => {
        if (touchStart && touchEnd) {
            handleSwipe();
        }
    };

    return (
        <div className="AnnoucementSectionMobile" id="annoucement">
            <div className="motion-div">
                <div
                    className="top"
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                >
                    {annoucements.map((item, index) => {
                        const isCurrent = currentAnnoucement?.id === item.id;
                        const isPrevious = previousAnnoucement?.id === item.id;
                        return (
                            <ImageOrVideo
                                key={item.id}
                                className={`img-current
                                    ${isCurrent ? '-active' : ''}  
                                    ${isPrevious ? '-last' : ''}
                                    ${!isCurrent && !isPrevious ? '-hidden' : ''}
                                `}
                                ext={
                                    item?.PictureOrVideoMobile?.data?.attributes.ext ||
                                    item.PictureOrVideo.data.attributes.ext
                                }
                                mime={
                                    item?.PictureOrVideoMobile?.data?.attributes.mime ||
                                    item.PictureOrVideo.data.attributes.mime
                                }
                                childrenImage={
                                    <img
                                        src={
                                            item?.PictureOrVideoMobile?.data?.attributes.url ||
                                            item.PictureOrVideo.data.attributes.url
                                        }
                                        alt={item.Title}
                                        className="img"
                                        onClick={() => window.open(item.redirectionUrl)}
                                    />
                                }
                                childrenVideo={
                                    <div className="wrapper-video-annoucement">
                                        <button
                                            className="play-btn btn-delete-default-style"
                                            onClick={onClickPlay}
                                        >
                                            {!isPause ? (
                                                <PauseIcon
                                                    fill={theme['white']}
                                                    width={30}
                                                    height={30}
                                                />
                                            ) : (
                                                <PlayIcon
                                                    fill={theme['white']}
                                                    width={30}
                                                    height={30}
                                                />
                                            )}
                                        </button>

                                        <video
                                            muted={true}
                                            autoPlay={true}
                                            loop
                                            playsInline
                                            controls={false}
                                            className="video"
                                            onClick={() => window.open(item.redirectionUrl)}
                                        >
                                            <source
                                                src={
                                                    item?.PictureOrVideoMobile?.data?.attributes
                                                        .url ||
                                                    item.PictureOrVideo.data.attributes.url
                                                }
                                                type={
                                                    item?.PictureOrVideoMobile?.data?.attributes
                                                        .mime ||
                                                    item.PictureOrVideo.data.attributes.mime
                                                }
                                            />
                                        </video>
                                    </div>
                                }
                            />
                        );
                    })}

                    <div className="cta-container -left">
                        <ChevronLeftIcon fill="white" onClick={onClickPrev} />
                    </div>

                    <div className="cta-container -right">
                        <ChevronRightIcon fill="white" onClick={onClickNext} />
                    </div>

                    <WrapperSectionTitle className="text-white">
                        {Copydeck.annoucementSectionWhatsNews}
                    </WrapperSectionTitle>

                    <div className="overlay" />
                </div>

                <div className="bottom">
                    <div className="text-white text-body-medium-primary-font title">
                        <AnnoucementTitle annoucement={currentAnnoucement || undefined} />
                    </div>

                    {currentAnnoucement?.redirectionUrl && (
                        <button
                            className="text-white text-body-bold learn-more btn-delete-default-style"
                            onClick={() => onClickLearnMore(currentAnnoucement.redirectionUrl)}
                        >
                            {Copydeck.annoucementSectionLearnMoreAnnoucement}
                        </button>
                    )}

                    <div className="dots">
                        {annoucements.map((_, index) => (
                            <div
                                key={index}
                                className={`dot ${index === currentIndex ? '-active' : ''}`}
                                onClick={() => onClickOnDot(index)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnnoucementSectionMobile;
