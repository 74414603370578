import React from 'react';
import './index.scss';
import CardValue from './Components/card-value';
import { HeartIcon } from '../../../../Icons/heart-icon';
import { theme } from '../../../../Style/theme';
import { SmartIcon } from '../../../../Icons/smart-icon';
import { IdeeIcon } from '../../../../Icons/idee-icon';
import { TimeIcon } from '../../../../Icons/time-icon';
import { useTranslation } from 'react-i18next';
import Button from '../../../../Components/Button';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import CONSTANTS from '../../../../Constants';
import WrapperSectionTitle from '../../../../Components/WrapperSection/title';

const AboutUsCoresValueSectionPage = () => {
    const { t } = useTranslation();
    const nav = useCustomNavigation();
    const onClickLearnMore = () => nav(CONSTANTS.SCREEN.LIFE_AT_SPREEAI, undefined, '#benefits');

    return (
        <div className="AboutUsCoresValueSectionPage">
            <div className="top">
                <WrapperSectionTitle htmlTag="h2">
                    {t('AboutUsPage.core_value_title')}
                </WrapperSectionTitle>
                <div
                    className="text-body"
                    dangerouslySetInnerHTML={{
                        __html: t('AboutUsPage.core_value_desc'),
                    }}
                />
            </div>

            <div className="bottom">
                <div className="one-row">
                    <CardValue
                        className="card-value-selected"
                        icon={() => <HeartIcon fill={theme['white']} />}
                        title={t('AboutUsPage.core_value_1_title')}
                        description={t('AboutUsPage.core_value_1_desc')}
                    />
                    <CardValue
                        icon={() => <SmartIcon fill={theme['black-secondary-alt']} />}
                        title={t('AboutUsPage.core_value_2_title')}
                        description={t('AboutUsPage.core_value_2_desc')}
                    />
                </div>
                <div className="one-row">
                    <CardValue
                        icon={() => <IdeeIcon fill={theme['black-secondary-alt']} />}
                        title={t('AboutUsPage.core_value_3_title')}
                        description={t('AboutUsPage.core_value_3_desc')}
                    />
                    <CardValue
                        icon={() => <TimeIcon fill={theme['black-secondary-alt']} />}
                        title={t('AboutUsPage.core_value_4_title')}
                        description={t('AboutUsPage.core_value_4_desc')}
                    />
                </div>
            </div>

            <div className="container-button-learn-more btn-delete-default-style">
                <Button type="black" text={t('learn_more')} onClick={onClickLearnMore} />
            </div>
        </div>
    );
};

export default AboutUsCoresValueSectionPage;
