import React from 'react';

export interface LayoutPolicyNumberedTitleProps {
    number: number;
    title: string;
}

const LayoutPolicyNumberedTitle: React.FC<LayoutPolicyNumberedTitleProps> = ({ number, title }) => {
    const formattedTitle = title.endsWith('.') ? title.slice(0, -1) : title;

    return (
        <span className="LayoutPolicyNumberedTitle deprecated-text-display-small">
            {number}. &nbsp; <u>{formattedTitle}</u>
        </span>
    );
};

export default LayoutPolicyNumberedTitle;
