import React, { useEffect, useRef } from 'react';
import './how-it-works-video-section.scss';
import Button from '../../../../Components/Button';
import CONSTANTS from '../../../../Constants';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import WrapperVideo from '../../../../Components/WrapperVideo';

const HowItWorksVideoSection = () => {
    const nav = useCustomNavigation();
    const { t } = useTranslation();

    const { windowWidth } = useWindowDimensions();
    const isMobile = windowWidth < 700;
    const onClickDiscoverMore = () => nav(CONSTANTS.SCREEN.WHAT_IS_SPREEAI);

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        videoRef.current?.load();
    }, [isMobile]);

    return (
        <div className="HowItWorksVideoSection">
            <WrapperVideo
                className="video-wrapper"
                muted
                videoRef={videoRef}
                src={{
                    en: {
                        mobile: {
                            webm: require('../../../../Assets/MP4/view-demo-complete-vertical.webm'),
                            mp4: require('../../../../Assets/MP4/view-demo-complete-vertical.mp4'),
                        },
                        desktop: {
                            webm: require('../../../../Assets/MP4/view-demo-complete-title.webm'),
                            mp4: require('../../../../Assets/MP4/view-demo-complete-title.mp4'),
                        },
                    },
                    it: {
                        mobile: {
                            webm: require('../../../../Assets/MP4/view-demo-complete-vertical-it.webm'),
                            mp4: require('../../../../Assets/MP4/view-demo-complete-vertical-it.mp4'),
                        },
                        desktop: {
                            webm: require('../../../../Assets/MP4/view-demo-complete-title-it.webm'),
                            mp4: require('../../../../Assets/MP4/view-demo-complete-title-it.mp4'),
                        },
                    },
                    fr: {
                        mobile: {
                            webm: require('../../../../Assets/MP4/view-demo-complete-vertical-fr.webm'),
                            mp4: require('../../../../Assets/MP4/view-demo-complete-vertical-fr.mp4'),
                        },
                        desktop: {
                            webm: require('../../../../Assets/MP4/view-demo-complete-title-fr.webm'),
                            mp4: require('../../../../Assets/MP4/view-demo-complete-title-fr.mp4'),
                        },
                    },
                    es: {
                        mobile: {
                            mp4: require('../../../../Assets/MP4/view-demo-complete-vertical-es.mp4'),
                        },
                        desktop: {
                            mp4: require('../../../../Assets/MP4/view-demo-complete-title-es.mp4'),
                        },
                    },
                }}
                alt="test"
            />

            <div className="disclaimer text-body text-element-greys-secondary-alt">
                {t('HomePage.how_it_works_disclaimer')}
            </div>

            <Button
                type="black"
                text={t('discover_more')}
                onClick={onClickDiscoverMore}
                fullInMobile={false}
            />
        </div>
    );
};

export default HowItWorksVideoSection;
