import Copydeck from '../../../../i18n/Copydeck';
import company1Image from '../../../../Assets/Img/Home/company-1.webp';
import company2Image from '../../../../Assets/Img/Home/company-2.webp';
import './index.scss';
import WrapperSection from '../../../../Components/WrapperSection';

import BackgroundCmu from '../../../../Assets/Img/Home/background-cmu.png';
import BackgroundMit from '../../../../Assets/Img/Home/background-mit.png';

import BackgroundCmuMobile from '../../../../Assets/Img/Home/background-cmu-mobile.png';
import BackgroundMitMobile from '../../../../Assets/Img/Home/background-mit-mobile.webp';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';

const Partner = () => {
    const { windowWidth } = useWindowDimensions();
    const isMobile = windowWidth < 768;
    const IMAGES = [
        {
            image: company2Image,
            alt: Copydeck.homePagePartnerSectionCmuLogo,
        },
        {
            image: company1Image,
            alt: Copydeck.homePagePartnerSectionMitLogo,
        },
    ];
    return (
        <div className="Partner">
            <div className="wrapper-background">
                <img
                    src={isMobile ? BackgroundCmuMobile : BackgroundCmu}
                    width="52%"
                    alt="Patent background"
                    className="background"
                />
                <img
                    src={isMobile ? BackgroundMitMobile : BackgroundMit}
                    width="50%"
                    alt="Patent background"
                    className="background2"
                />
            </div>

            <div className="sub-overlay" />
            <div className="overlay" />
            <div className="overlay-bottom" />

            <div className="Partner__content">
                <WrapperSection.Title className="title text-white">
                    {Copydeck.homePagePartnerSectionPartnerTitle}
                </WrapperSection.Title>

                <div className="subtitles">
                    <p
                        className="subtitle text-white text-body"
                        dangerouslySetInnerHTML={{
                            __html: Copydeck.homePagePartnerSectionPartnerSubtitle,
                        }}
                    />

                    <p
                        className="subtitle text-white text-body"
                        dangerouslySetInnerHTML={{
                            __html: Copydeck.homePagePartnerSectionPartnerSubtitle2,
                        }}
                    />
                </div>

                <ul className="companies">
                    {IMAGES.map((company, index) => {
                        return (
                            <li key={index} className="compagny">
                                <img src={company.image} alt={company.alt} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Partner;
